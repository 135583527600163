import React, { useContext, useEffect, useState } from "react";
import logo from "../assets/image/logo.svg";
import sima from "../assets/image/sima.svg";
import { Link, NavLink } from "react-router-dom";
import { Sling as Hamburger } from "hamburger-react";
import QRCode from "react-qr-code";
import qrlogo from "../assets/image/qrLogo.svg";
import { NumberContext } from "../content/NumberContext";
import slugify from "react-slugify";
import { v4 as uuidv4 } from "uuid";
import axios from "axios";
import { jwtDecode } from "jwt-decode";
import Countdown from "react-countdown";
import translations from "../data/langData";
import { LanguageContext } from "../context/LanguageContext";
const Header = () => {
  const { language, changeLanguage } = useContext(LanguageContext);
  const t = translations[language];
  const handleLanguageChange = (event) => {
    const newLanguage = event.target.value;
    changeLanguage(newLanguage);
  };

  const [user, setUser] = useState({});
  const [isOpen, setOpen] = useState(false);
  const [mobilePage, setMobilePage] = useState(false);
  const { numbers, loading, error } = useContext(NumberContext);
  const uniqueModes = new Set();
  const uniqueModesMobile = new Set();

  const [id, setId] = useState(
    JSON.parse(localStorage.getItem("uuid")) || uuidv4()
  );
  localStorage.setItem("uuid", JSON.stringify(id));
  const [status, setStatus] = useState(0);
  const [qrLink, setQrLink] = useState("");
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const checkIfMobile = () => {
      const userAgent = navigator.userAgent || navigator.vendor || window.opera;
      if (/android/i.test(userAgent) || /iPad|iPhone|iPod/.test(userAgent)) {
        setIsMobile(true);
      } else {
        setIsMobile(false);
      }
    };

    checkIfMobile();

    const handleScan = async (operId) => {
      try {
        const response = await axios.post(
          "https://mynumber.az/simaa/qrcode.php",
          { operationId: operId }
        );
        const data = response.data;
        setQrLink(data.qrUrl);
      } catch (error) {
        console.log("XETA BAS VERDI :", error);
      }
    };
    handleScan(id);

    const fetchStatus = async () => {
      try {
        const response = await axios.post(
          "https://mynumber.az/simaa/statusAuth.php",
          { operationId: id }
        );
        setStatus(response.data.status);
      } catch (error) {
        console.log("APIDE XETA :", error);
      }
    };
    fetchStatus();

    const intervalId = setInterval(fetchStatus, 5000);
    if (status === 1) {
      const fetchUser = async () => {
        try {
          // Check if the user data already exists in localStorage to prevent multiple fetch calls
          const userLS = localStorage.getItem("user");
          if (userLS) {
            return; // User is already fetched, no need to call the API again
          }

          // If user data doesn't exist, make the API call to fetch user info
          const response = await axios.post(
            "https://mynumber.az/simaa/userInfo.php",
            { operationId: id }
          );

          // Store the user data and logged-in flag in localStorage
          localStorage.setItem("user", JSON.stringify(response.data.jwt));
          localStorage.setItem("log", JSON.stringify(1));

   

          // Trigger a reload only once after successfully setting the user data
          window.location.reload();
        } catch (error) {
          console.error("Xeta burdadir", error);
        }
      };

      fetchUser();
      // window.location.reload();
    } else {
      localStorage.setItem("log", JSON.stringify(0));
    }
    // Component unmount olduqda intervalı təmizlə
    return () => clearInterval(intervalId);
  }, [status, id]);
  useEffect(() => {
    const userLS = localStorage.getItem("user");
    if (userLS) {
      const decodedUser = jwtDecode(JSON.parse(userLS));
      setUser(decodedUser.data[0]);
    } else {
      setUser();
    }
  }, [localStorage.getItem("user")]);

  const logOut = () => {
    localStorage.removeItem("user");
    localStorage.removeItem("uuid");
    localStorage.setItem("log", JSON.stringify(0));
    window.location.reload();
  };

  return (
    <>
      <section className="header">
        <div className="d-flex justify-content-between align-items-center  p-2 px-4">
          <div className="logo">
            <NavLink to="/" id="noActive" >
              <img
                src={logo}
                alt=""
                className="img-fluid"
              />
            </NavLink>
          </div>
          <div className="d-flex  align-items-center">
              {/* <Link to="/business" className="mx-3">Biznes nömrələr</Link> */}
            <div className="menu-item">
              <a href="#">{t.numbers}</a>
              <ul className="submenu">

                {numbers.map((item) => {
                  // Eğer bu mode daha önce render edilmişse, bu öğeyi atla
                  if (uniqueModes.has(item.mode)) {
                    return null; // Aynı mode'dan tekrar varsa, render etmiyoruz
                  }

                  // Mode'yu Set'e ekliyoruz
                  uniqueModes.add(item.mode);

                  // Mode'ya göre uygun metni belirliyoruz
                  let modeText = "";
                  switch (item.mode) {
                    case "1":
                      modeText = "Classic";
                      break;
                    case "2":
                      modeText = "Silver";
                      break;
                    case "3":
                      modeText = "Gold";
                      break;
                    case "4":
                      modeText = "Platinium";
                      break;
                      
                    default:
                      modeText = "";
                  }

                  return (
                    <NavLink to={`/${slugify(`${item.mode}-${modeText}`)}`}>
                      <li
                        key={item.id}
                        style={{ borderBottom: "1px solid #E4E4E4" }}
                      >
                        {modeText}
                      </li>
                    </NavLink>
                  );
                })}
              </ul>
            </div>

            <NavLink to="top-up" className="mx-3" activeCLassName="active">
              {t.topUp}
            </NavLink>
            <Link to="/about" className="me-2">
              {t.about}
            </Link>
            <select
              name=""
              id="headerSelect"
              value={language}
              onChange={handleLanguageChange}
            >
              <option value="az">AZ</option>
              <option value="en">EN</option>
              <option value="ru">RU</option>
            </select>
            <div className="sima ms-3">
              {!user ? (
                <div>
                  <div
                    className="modal fade"
                    id="exampleModalToggle"
                    aria-hidden="true"
                    aria-labelledby="exampleModalToggleLabel"
                    tabIndex={-1}
                  >
                    <div className="modal-dialog modal-dialog-centered">
                      <div className="modal-content">
                        <div className="modal-header">
                          <h1
                            className="modal-title fs-5"
                            id="exampleModalToggleLabel"
                          >
                            {t.signIn}
                          </h1>
                          <button
                            type="button"
                            className="btn-close"
                            data-bs-dismiss="modal"
                            aria-label="Close"
                          />
                        </div>
                        <div
                          className="modal-body btn1"
                          data-bs-target="#exampleModalToggle2"
                          data-bs-toggle="modal"
                        >
                          <img src={sima} alt="" className="img-fluid" />
                          <span className="ms-2"> {t.simaTitle} </span>
                        </div>
                        {/* <div className="modal-footer">
                        <button
                          className="btn"
                          data-bs-target="#exampleModalToggle2"
                          data-bs-toggle="modal"
                        >
                          Open second modal
                        </button>
                      </div> */}
                      </div>
                    </div>
                  </div>
                  <div
                    className="modal fade"
                    id="exampleModalToggle2"
                    aria-hidden="true"
                    aria-labelledby="exampleModalToggleLabel2"
                    tabIndex={-1}
                  >
                    <div className="modal-dialog modal-dialog-centered">
                      <div className="modal-content">
                        <div className="modal-header">
                          <h1
                            className="modal-title fs-5"
                            id="exampleModalToggleLabel2"
                          >
                            <img src={sima} alt="" className="img-fluid" />
                            <br />
                          </h1>
                          <button
                            type="button"
                            className="btn-close"
                            data-bs-dismiss="modal"
                            aria-label="Close"
                          />
                        </div>
                        <div className="modal-body1 simaModal">
                          <div className="d-flex justify-content-center align-items-center flex-column my-3">
                            <QRCode value={qrLink && qrLink} size={200} />
                            {isMobile && (
                              <div className="sima-btn">
                                <Link
                                  to={`sima://web-to-app?data=${
                                    qrLink && qrLink
                                  }`}
                                >
                                  SİMA tətbiqinə keçid et
                                </Link>
                              </div>
                            )}
                            <Countdown
                              overtime={true}
                              date={Date.now() + 1000 * 60 * 5}
                              onComplete={() => {
                                const newOperationId = uuidv4();
                                localStorage.setItem(
                                  "uuid",
                                  JSON.stringify(newOperationId)
                                );
                                setId(newOperationId);
                              }}
                              renderer={({ minutes, seconds }) => (
                                <div>
                                  <span className="countdown">
                                    {t.finishTime} {minutes}
                                  </span>
                                  :
                                  <span>
                                    {seconds < 10 ? `0${seconds}` : seconds}
                                  </span>
                                </div>
                              )}
                            />
                          </div>
                        </div>
                        <div className="simaModal">
                          <div className="my-3">
                            <div className="d-flex align-items-center">
                              <div className="circle"></div>
                              <span
                                className="ms-2"
                                style={{ fontSize: "12px", fontWeight: "bold" }}
                              >
                                {t.simaTitle1}
                              </span>
                            </div>
                            <div className="d-flex align-items-center my-2">
                              <div className="circle"></div>
                              <span
                                className="ms-2"
                                style={{ fontSize: "12px", fontWeight: "bold" }}
                              >
                                {t.simaTitle2}
                                <img
                                  src={qrlogo}
                                  alt=""
                                  className="img-fluid"
                                />{" "}
                                {t.simaTitle21}
                              </span>
                            </div>
                            <div className="d-flex align-items-center">
                              <div className="circle"></div>
                              <span
                                className="ms-2"
                                style={{ fontSize: "12px", fontWeight: "bold" }}
                              >
                                {t.simaTitle3}
                              </span>
                            </div>
                          </div>
                        </div>
                        <div className="simaModal">
                          <div className="">
                            <div className="d-flex align-items-center">
                              <QRCode
                                value="https://play.google.com/store/apps/details?id=az.dpc.sima&hl=tr"
                                size={200}
                              />
                              <p className="ms-3">
                                {t.simaTitle4}
                                <br />
                                <span
                                  className="text-secondary"
                                  style={{ fontSize: "small" }}
                                >
                                  {t.simaDesc}
                                </span>
                              </p>
                            </div>
                          </div>
                        </div>
                        <div className="modal-footer">
                          <button
                            className="btn "
                            data-bs-target="#exampleModalToggle"
                            data-bs-toggle="modal"
                          >
                            <i className="fa-solid fa-arrow-left"></i> {t.back}
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                  <button
                    className="btn "
                    data-bs-target="#exampleModalToggle"
                    data-bs-toggle="modal"
                  >
                    {t.signIn}
                  </button>
                </div>
              ) : (
                <>
                  <button className="btn fs-6">{user.name} </button>
                  <i
                    className="fa-solid fa-arrow-right-from-bracket text-danger ms-2"
                    style={{ cursor: "pointer" }}
                    onClick={logOut}
                  ></i>
                </>
              )}

              {/* <img src={sima} alt="" className="img-fluid" /> */}
            </div>
            <div className="hamburgerIcon">
              <Hamburger toggled={isOpen} toggle={setOpen} color="#2abee7" />
            </div>
          </div>
        </div>
      </section>
      <div className={`canvas ${isOpen ? "activeCanvas" : ""}`}>
        <div className="d-flex justify-content-between align-items-center p-3">
          <NavLink to="/" onClick={() => setOpen(false)}>
            <img src={logo} alt="" className="img-fluid" />
          </NavLink>
          <i className="fa-solid fa-xmark" onClick={() => setOpen(false)}></i>
        </div>
        <ul>
          <li
            onClick={() => setMobilePage(!mobilePage)}
            style={{ cursor: "pointer" }}
          >
            <div className="d-flex justify-content-between">
              {t.numbers}
              <i
                className={`fa-solid fa-chevron-${
                  mobilePage ? "up" : "down"
                } me-3`}
                style={{ color: "#30D3FF" }}
              ></i>
            </div>
            <ul className={`${mobilePage ? "d-block" : "d-none"}`}>
              {numbers.map((item) => {
                // Eğer bu mode daha önce render edilmişse, bu öğeyi atla
                if (uniqueModesMobile.has(item.mode)) {
                  return null; // Aynı mode'dan tekrar varsa, render etmiyoruz
                }

                // Mode'yu Set'e ekliyoruz
                uniqueModesMobile.add(item.mode);

                // Mode'ya göre uygun metni belirliyoruz
                let modeText = "";
                switch (item.mode) {
                  case "1":
                    modeText = t.category;
                    break;
                  case "2":
                    modeText = t.category1;
                    break;
                  case "3":
                    modeText = t.category2;
                    break;
                  case "4":
                    modeText = t.category3;
                    break;
                  default:
                    modeText = "unknown";
                }

                return (
                  <NavLink
                    to={`/${slugify(`${item.mode}-${modeText}`)}`}
                    id="noActive"
                    onClick={() => {
                      setOpen(false);
                    }}
                  >
                    <li
                      key={item.id}
                      style={{ borderBottom: "1px solid #E4E4E4" }}
                    >
                      {modeText}
                    </li>
                  </NavLink>
                );
              })}
            </ul>
            <hr className="me-3" />
          </li>
          <li>
            <NavLink
              to="/top-up"
              activeCLassName="active"
              onClick={() => setOpen(false)}
            >
              {t.topUp}
            </NavLink>
            <hr className="me-3" />
          </li>
          <li>
            <NavLink
              to="/about"
              activeCLassName="active"
              onClick={() => setOpen(false)}
            >
              {t.about}
            </NavLink>
            <hr className="me-3" />
          </li>
        </ul>
        <div className=" mx-3">
          <select
            name=""
            id="headerSelect"
            value={language}
            onChange={handleLanguageChange}
          >
            <option value="az">AZ</option>
            <option value="en">EN</option>
            <option value="ru">RU</option>
          </select>
          <div className="sima mt-3">
            {!user ? (
              <div>
                <div
                  className="modal fade"
                  id="exampleModalToggle"
                  aria-hidden="true"
                  aria-labelledby="exampleModalToggleLabel"
                  tabIndex={-1}
                >
                  <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content">
                      <div className="modal-header">
                        <h1
                          className="modal-title fs-5"
                          id="exampleModalToggleLabel"
                        >
                          Daxil ol
                        </h1>
                        <button
                          type="button"
                          className="btn-close"
                          data-bs-dismiss="modal"
                          aria-label="Close"
                        />
                      </div>
                      <div
                        className="modal-body btn1"
                        data-bs-target="#exampleModalToggle2"
                        data-bs-toggle="modal"
                      >
                        <img src={sima} alt="" className="img-fluid" />
                        <span className="ms-2"> SİMA Rəqəmsal İmza ilə </span>
                      </div>
                      {/* <div className="modal-footer">
                        <button
                          className="btn"
                          data-bs-target="#exampleModalToggle2"
                          data-bs-toggle="modal"
                        >
                          Open second modal
                        </button>
                      </div> */}
                    </div>
                  </div>
                </div>
                <div
                  className="modal fade"
                  id="exampleModalToggle2"
                  aria-hidden="true"
                  aria-labelledby="exampleModalToggleLabel2"
                  tabIndex={-1}
                >
                  <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content">
                      <div className="modal-header">
                        <h1
                          className="modal-title fs-5"
                          id="exampleModalToggleLabel2"
                        >
                          <img src={sima} alt="" className="img-fluid" />
                          <br />
                        </h1>
                        <button
                          type="button"
                          className="btn-close"
                          data-bs-dismiss="modal"
                          aria-label="Close"
                        />
                      </div>
                      <div className="modal-body1 simaModal">
                        <div className="d-flex justify-content-center align-items-center flex-column my-3">
                          <QRCode value={qrLink && qrLink} size={200} />
                          {isMobile && (
                            <div className="sima-btn">
                              <Link
                                to={`sima://web-to-app?data=${
                                  qrLink && qrLink
                                }`}
                              >
                                SİMA tətbiqinə keçid et
                              </Link>
                            </div>
                          )}
                          <Countdown
                            overtime={true}
                            date={Date.now() + 1000 * 60 * 5}
                            onComplete={() => {
                              const newOperationId = uuidv4();
                              localStorage.setItem(
                                "uuid",
                                JSON.stringify(newOperationId)
                              );
                              setId(newOperationId);
                            }}
                            renderer={({ minutes, seconds }) => (
                              <div>
                                <span className="countdown">
                                  Bitmə vaxtı: {minutes}
                                </span>
                                :
                                <span>
                                  {seconds < 10 ? `0${seconds}` : seconds}
                                </span>
                              </div>
                            )}
                          />
                        </div>
                      </div>
                      <div className="simaModal">
                        <div className="my-3">
                          <div className="d-flex align-items-center">
                            <div className="circle"></div>
                            <span
                              className="ms-2"
                              style={{ fontSize: "12px", fontWeight: "bold" }}
                            >
                              SİMA mobil tətbiqini açın.
                            </span>
                          </div>
                          <div className="d-flex align-items-center my-2">
                            <div className="circle"></div>
                            <span
                              className="ms-2"
                              style={{ fontSize: "12px", fontWeight: "bold" }}
                            >
                              Skan et düyməsini{" "}
                              <img src={qrlogo} alt="" className="img-fluid" />{" "}
                              sıxın.
                            </span>
                          </div>
                          <div className="d-flex align-items-center">
                            <div className="circle"></div>
                            <span
                              className="ms-2"
                              style={{ fontSize: "12px", fontWeight: "bold" }}
                            >
                              QR kodu skan edərək daxil olun.
                            </span>
                          </div>
                        </div>
                      </div>
                      <div className="simaModal">
                        <div className="">
                          <div className="d-flex align-items-center">
                            <QRCode
                              value="https://play.google.com/store/apps/details?id=az.dpc.sima&hl=tr"
                              size={200}
                            />
                            <p className="ms-3">
                              Sima mobil tətbiqiniz yoxdur?
                              <br />
                              <span
                                className="text-secondary"
                                style={{ fontSize: "small" }}
                              >
                                Hesabınız yoxdursa, mobil telefonunuzla QR kodu
                                skan edərək tətbiqi yükləyib hesab yarada
                                bilərsiniz
                              </span>
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className="modal-footer">
                        <button
                          className="btn "
                          data-bs-target="#exampleModalToggle"
                          data-bs-toggle="modal"
                        >
                          <i className="fa-solid fa-arrow-left"></i> Geri
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
                <button
                  className="btn"
                  style={{ background: "#2abee7", color: "#fff" }}
                  data-bs-target="#exampleModalToggle"
                  data-bs-toggle="modal"
                >
                  {t.signIn}
                </button>
              </div>
            ) : (
              <>
                <button className="btn fs-6">{user.name} </button>
                <i
                  className="fa-solid fa-arrow-right-from-bracket text-danger ms-2"
                  style={{ cursor: "pointer" }}
                  onClick={logOut}
                ></i>
              </>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default Header;
