import React, { useState, useEffect, useContext } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Header from "../components/Header";
import Footer from "../components/Footer";
import MainInput from "../pages/MainInput";
import TopUp from "../pages/TopUp";
import left from "../assets/image/leftgif.gif";
import right from "../assets/image/rightgif.gif";
import Numbers from "../pages/Numbers";
import Order from "../pages/Order";
import About from "../pages/About";
import SeoNumbers from "../pages/SeoNumbers";
import "aos/dist/aos.css";
import { NumberContext } from "../content/NumberContext";
import Success from "../pages/Success";
import RedirectOnStatus from "../pages/Redirect";
import ScrollToTop from "react-scroll-to-top";
import Business from "../pages/Business";
import Discount30 from "../pages/Discount30";
import DiscountMinute from "../pages/DiscountMinute";
import DiscountGb from "../pages/DiscountGb";
import business from "../assets/image/business.gif";
import wpimg from "../assets/image/wp.png" ;
import Vip from "../pages/Vip";
const AppRouter = () => {
  const { numbers, loading, error } = useContext(NumberContext);
  const localWin = localStorage.getItem("localwin");

  // State for alternating images
  const [showLeftImage, setShowLeftImage] = useState(true);
  const [showRightImage, setShowRightImage] = useState(false);

  useEffect(() => {
    // Interval to toggle the images every 2 seconds
    const interval = setInterval(() => {
      setShowLeftImage((prev) => !prev); // Toggle left image
      setShowRightImage((prev) => !prev); // Toggle right image
    }, 3000);

    // Cleanup interval on component unmount
    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    // `localStorage`-i silmək üçün `useEffect` əlavə etdik
    const storedStartTime = localStorage.getItem("startTime");

    if (storedStartTime) {
      const timeRemaining = Date.now() - parseInt(storedStartTime, 10);
      if (timeRemaining > 0) {
        // `Countdown` bitib, `localStorage`-i silirik
        localStorage.removeItem("startTime");
        localStorage.removeItem("win");
        localStorage.removeItem("30");
        window.location.reload();
      }
    }
  }, []);

  return (
    <BrowserRouter>
      <div className="container-fluid p-0">
        <div className="row">
          <div
            className="col-12 col-md-1 p-0 leftImage"
            style={{
              flex: 2.5,
              position: "sticky",
              top: "0",
              height: `${!loading ? "100%" : "100vh"}`,
            }}
          >
            <img src={business} alt="" className="img-fluid" />
          </div>

          <div className="col-12 col-md-10 p-0" style={{ flex: 10 }}>
            <Header />
            <Routes>
              <Route path="/" element={<MainInput />} />
              <Route path="/top-up" element={<TopUp />} />
              <Route path="/about" element={<About />} />
              <Route path="/:number" element={<Numbers />} />
              <Route path="/order/:orderId" element={<Order />} />
              <Route path="/discount30" element={<Discount30 />} />
              <Route path="/discountmin" element={<DiscountMinute />} />
              <Route path="/discountgb" element={<DiscountGb />} />

               {/* SEO START */}
              <Route path="/azercell-nomre" element={<SeoNumbers />} />
              <Route path="/azercell-nomreler" element={<SeoNumbers />} />
              <Route path="/azercell-nomre-al" element={<SeoNumbers />} />
              <Route path="/azercell-211" element={<SeoNumbers />} />
              <Route path="/onlayn-nomre-almaq" element={<SeoNumbers />} />
              <Route path="/211-nomreler" element={<SeoNumbers />} />
              <Route path="/azercell-onlayn-nomreler" element={<SeoNumbers />} />
              <Route path="/vip-azercell-nomreler" element={<SeoNumbers />} />
              <Route path="/azercell-esim" element={<SeoNumbers />} />
              <Route path="/esim-almaq" element={<SeoNumbers />} />
              <Route path="/onlayn-esim-al" element={<SeoNumbers />} />
              <Route path="/onlayn-nomre" element={<SeoNumbers />} />
              <Route path="/asan-imza-almaq" element={<SeoNumbers />} />
              <Route path="/asan-imza" element={<SeoNumbers />} />
              <Route path="/sim-nomreler" element={<SeoNumbers />} />
              <Route path="/50-nomreler" element={<SeoNumbers />} />
              <Route path="/51-nomreler" element={<SeoNumbers />} />
              <Route path="/10-nomreler" element={<SeoNumbers />} />
              <Route path="/klassik-nomreler" element={<SeoNumbers />} />
              <Route path="/bahali-nomreler" element={<SeoNumbers />} />
              <Route path="/ucuz-nomreler" element={<SeoNumbers />} />
              <Route path="/ucuz-azercell-nomreleri" element={<SeoNumbers />} />
              <Route path="/vip-nomreleri" element={<SeoNumbers />} />
              <Route path="/fakturali-nomreler" element={<SeoNumbers />} />
              <Route path="/fakturasiz-nomreler" element={<SeoNumbers />} />
              <Route path="/onlayn-sim-nomreler" element={<SeoNumbers />} />
              {/* SEO END */}

              <Route path="/success" element={<Success />} />
              <Route path="/redirect" element={<RedirectOnStatus />} />

              <Route path="/vip" element={<Vip />} />
            </Routes>
            <Footer />
            {/* <ScrollToTop
              component={<i className="fa-solid fa-angle-up"></i>}
              style={{
                background: "transparent",
                color: "#fff",
                border: "3px solid #fff",
                borderRadius: "20px",
                fontSize: "20px",
              }}
            /> */}
            <a class="whapp animated pulse" href="https://wa.me/994512070940" target="_blank">
              <div class="whapp-btn">
                <img src={wpimg} />
              </div>
            </a>
          </div>

          <div
            className="col-12 col-md-1 p-0 leftImage"
            style={{
              flex: 2.5,
              position: "sticky",
              top: "0",
              height: `${!loading ? "100%" : "100vh"}`,
            }}
          >
            {!loading && showRightImage && (
              <img
                src={right}
                alt="Right"
                className="img-fluid"
                loading="lazy"
              />
            )}
            {!loading && !showRightImage && (
              <img
                src={left}
                alt="Right"
                className="img-fluid"
                loading="lazy"
              />
            )}
          </div>
        </div>
      </div>
    </BrowserRouter>
  );
};

export default AppRouter;
